@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);

/* *{
  border: red 1px solid;
} */

/* SERIF FONT FOR LOGO */
@font-face {
  font-family: DxSitrus-Expanded;
  src: url(/static/media/DxSitrus-Expanded.4dbc7241.otf) format("opentype");
}

/* SANS SERIF ROBOTO */

*{
  font-family: 'Roboto', sans-serif;
}

.logo{
  font-family: DxSitrus-Expanded;
  font-size: 2rem;
  margin: .9rem;
}






.section {
  top: 4rem;
  position: relative;
}

.tesimonial-wapper{
  padding: 0 2rem;
}
.tesimonial-wapper > h2{
  margin-bottom: 3rem;
}




/* to vertical align center testimonial Col  */
/* https://getbootstrap.com/docs/5.0/components/carousel/ */
/* https://www.sitepoint.com/community/t/bootstrap-5-carousel-how-to-vertically-center-text-inside-items/375666/2 */
.carousel-item > div {
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* to position  ".quote-left" & ".quote-right" */
/* text of testimonial */
.blockquote-custom {
  position: relative;
  font-size: 1.1rem;
  padding: 0 20% !important;
}

/* quote icon holder - FontAwesome */
/* https://www.digitalocean.com/community/tutorials/how-to-use-font-awesome-5-with-react */
/* https://fontawesome.com/search?q=left%20qoute&s=solid%2Cbrands */
.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.quote-left{
  position: absolute;
  top: 0px;
  left: 0px;
}

.quote-right{
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* name of testimonial */
.blockquote-footer-custom {
  text-align: right;
  font-size: 1.3rem !important;
  color: #6c757d !important;
}

/* 
  Services CSS
  https://bootstrapious.com/p/bootstrap-quote
 */
.image{
  border-radius: 3rem;
  margin-bottom: 1.5rem;
}

/* 
  General CSS
  https://bootstrapious.com/p/bootstrap-quote
 */
h1{
  padding-bottom: 2rem;
}

h3{
  padding-bottom: 0.7rem;
}
p{
  padding-bottom: 2rem;
}




/* CUSTOM HEADINGS */
.h1-headings{
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  text-align: center;
}


a {
  text-decoration: none !important ;
  color: black!important;
}


.btn-link {
  color: #fff !important;
}

/* btn on the bottom on all pages */
.sticky-book-btn {
  position: fixed;
  margin: 1.5rem;
  bottom: 1.5rem;
  right: 1.5rem;
}

.book-btn {
  cursor: pointer;
  padding: 1.5rem;
  border-radius: 100px;
  border: none;
}

@media only screen and (max-width: 600px) {
  .sticky-book-btn {
    margin: 0.2rem;
    bottom: 0.1rem;
    right: 0.1rem;
  }
}



/* VIDEO BG */
.video-background{
  z-index: -1;
  width: 100%;
}

.video-container {
  position: relative;
  /* width: 100%; */
  height: 100vh;
  overflow: hidden;
}
.video-container > video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.video-container video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* trying to fix the whole website shifting bc different length of the testimonials */
.carousel-inner{
  min-height:40rem;
}
@media only screen and (max-width: 600px) {
  .carousel-inner{
    min-height:60rem;
  }
}
@media only screen and (max-width: 400px) {
  .carousel-inner{
    min-height:70rem;
  }
}

